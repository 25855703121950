
<template>
  <div id="echarts">
    <!-- echarts 装载标签 -->
    <div ref="echarts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import { CandlestickChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  CandlestickChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  // 父组件在标签中传递过来的数据
  props: {
    echartGetDatasFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      echart: {
        chart: {},
        datas: {
          account_balance: "",
          available_balance: "",
          sector_pledge_balance: "",
          vesting_funds: "",
          available_balance_val: 0,
          sector_pledge_balance_val: 0,
          vesting_funds_val: 0,
        },
      },
    };
  },
  watch: {
    echartGetDatasFlag(newVal) {
      if (newVal)
        this.echarts_update_datas(
          JSON.parse(sessionStorage.getItem("echart-datas"))
        );
    },
    "echarts.sec"() {
      this.getCandlestickData();
    },
  },
  methods: {
    // K线图echarts图形化初始化
    echart_init() {
      /** 判断带有 ref 的标签是否存在
       * 这个 ref 是VUE的东西，获取的是需要装载 echarts 的 dom 元素对象
       * 等于 jq 的 $('#echarts')[0]
       * 等于 js 的 document.getElementById('echarts')
       */
      if (!this.$refs["echarts"]) return;

      //   初始化 echarts, echarts.init(dom);我这里是存起来，方便其他地方调用
      this.echart.chart = echarts.init(this.$refs["echarts"]);
      // 初始化数据
      let datas = sessionStorage.getItem("echart-datas")
        ? JSON.parse(sessionStorage.getItem("echart-datas"))
        : this.echart.datas;

      // 图表的设置
      const option = {
        title: {
          text: "账户余额：" + datas.account_balance,
          right: "0",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "0",
          bottom: "0",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
          formatter: function (params) {
            let a = "";
            switch (params) {
              case "可用余额":
                a = datas.available_balance;
                break;
              case "扇区质押":
                a = datas.sector_pledge_balance;
                break;
              case "收益锁仓":
                a = datas.vesting_funds;
                break;
            }
            return params + ":\n" + a;
          },
        },
        series: {
          type: "pie",
          radius: ["40%", "80%"],
          center: ["40%", "50%"],
          avoidLabelOverlap: false,
          // 边框
          itemStyle: {
            borderRadius: 3,
            borderColor: "#3d447b",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
          },
          // 鼠标悬浮
          emphasis: {
            label: {
              show: true,
              color: "#fff",
              fontSize: "20",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: datas.available_balance_val, name: "可用余额" },
            { value: datas.sector_pledge_balance_val, name: "扇区质押" },
            { value: datas.vesting_funds_val, name: "收益锁仓" },
          ],
        },
      };

      // 渲染图表
      if (option && typeof option === "object") {
        //这里用延迟是因为这个叼毛东西在VUE环境下有问题，需要延迟执行
        setTimeout(() => {
          // 渲染图表 chart.setOption(option, Boolean), 第二个参数默认 false ,如果ture 就是全部重新弄，如果不填就是更新并渲染图表
          this.echart.chart.setOption(option, true);
          this.echarts_response();
        }, 100);
      }
    },
    echarts_response() {
      const width = document.body.clientWidth;
      // console.log(width);
      if (width < 375) {
        const dom = this.$refs["echarts"];
        const width = (dom.clientWidth / 2) * 0.7;
        dom.style.height = dom.clientWidth + 28 + "px";

        this.echart.chart.setOption({
          legend: {
            orient: "horizontal",
          },
          series: {
            radius: [width / 2, width],
            center: ["50%", width + 28],
          },
        });
        this.echart.chart.resize();
      }
    },
    echarts_update_datas(datas) {
      if (!datas) return false;

      this.echart.chart.setOption({
        title: {
          text: "账户余额：" + datas.account_balance,
        },
        legend: {
          formatter: function (params) {
            let a = "";
            switch (params) {
              case "可用余额":
                a = datas.available_balance;
                break;
              case "扇区质押":
                a = datas.sector_pledge_balance;
                break;
              case "收益锁仓":
                a = datas.vesting_funds;
                break;
            }
            return params + ":\n" + a;
          },
        },
        series: {
          data: [
            { value: datas.available_balance_val, name: "可用余额" },
            {
              value: datas.sector_pledge_balance_val,
              name: "扇区质押",
            },
            { value: datas.vesting_funds_val, name: "收益锁仓" },
          ],
        },
      });
      this.echart.chart.resize();
    },
    getTeamReport() {},
  },
  created() {
    // （this）data、methods 已初始化，数据（data）、方法(methods) 尚未 编译成模版，只是存在内存中
    const intervalID = setInterval(() => {
      const dom = this.$refs["echarts"];
      if (dom && echarts) {
        this.echart_init();
        this.echarts_update_datas();
        clearInterval(intervalID);
      }
    }, 10);
  },
};
</script>

<style lang="less" scoped>
#echarts {
  > div {
    height: 12em;
  }
}
</style>