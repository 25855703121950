
<template>
  <div id="Ore-Pool-View">
    <div id="miner-overview">
      <div class="top">
        <div class="title components">存储服务提供者概览</div>
      </div>
      <a-spin class="bottom" :spinning="false">
        <a-row>
          <a-col :span="24">
            <p>存储服务提供者地址</p>
            <p>{{ overview.tag_name }}</p>
          </a-col>
        </a-row>
        <a-row
          :style="{
            padding: 0,
            margin: 0,
          }"
        >
          <Echart
            :echartGetDatasFlag="echartGetDatasFlag"
            :style="{ width: '100%' }"
          />
        </a-row>
        <a-row>
          <a-col :span="24">
            <span>有效算力</span>
            <span>{{ overview.quality_power }}</span>
          </a-col>
          <a-col :span="24">
            <span>有效算力占比</span>
            <span>{{ overview.quality_power_ratio }}</span>
          </a-col>
          <!-- <a-col>
            <span>排名</span>
            <span>{{ overview.rank }}</span>
          </a-col> -->
        </a-row>
        <a-row>
          <a-col :span="24">
            <span>原值算力</span>
            <span>{{ overview.raw_power }}</span>
          </a-col>
          <a-col :span="24">
            <span>
              累计出块份数
              <a-tooltip placement="top">
                <template slot="title">
                  Filecoin存储服务模型中，一个高度（tipset）下可能有多个区块（block），每个区块可能获得多份奖励（win
                  count）。累计出块份数=每次出块获得奖励份数的总和
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <span>{{ overview.weighted_blocks_mined }}</span>
          </a-col>
          <a-col :span="24">
            <span>累计出块奖励</span>
            <span>{{ overview.total_rewards }}</span>
          </a-col>
          <a-col :span="24">
            <span>扇区大小</span>
            <span>{{ overview.sector_size }}</span>
          </a-col>
          <a-col :span="24">
            <span>扇区状态</span>
            <span>
              <a-row>
                <a-col :span="24">{{ overview.sectors_live }} 全部</a-col>
                <a-col :span="24">{{ overview.sectors_active }} 有效</a-col>
                <a-col :span="24">{{ overview.sectors_faulty }} 错误</a-col>
                <a-col :span="24">
                  {{ overview.sectors_recovering }}
                  恢复中
                </a-col>
              </a-row>
            </span>
          </a-col>
        </a-row>
      </a-spin>
    </div>
    <div id="miner-statistics">
      <div class="top">
        <div class="title components">
          <div class="left">存储服务统计</div>
          <div class="right">
            <a-radio-group
              v-model="duration"
              v-if="500 < $store.state.windowWidth"
            >
              <a-radio-button value="24h"> 24小时 </a-radio-button>
              <a-radio-button value="7d"> 7天 </a-radio-button>
              <a-radio-button value="30d"> 30天 </a-radio-button>
              <!-- <a-radio-button value="1y"> 1年 </a-radio-button> -->
            </a-radio-group>
            <a-select
              v-else
              default-value="24h"
              style="width: 120px"
              @change="(value) => (duration = value)"
            >
              <a-select-option value="24h"> 24小时 </a-select-option>
              <a-select-option value="7d"> 7天 </a-select-option>
              <a-select-option value="30d"> 30天 </a-select-option>
              <!-- <a-select-option value="1y"> 1年 </a-select-option> -->
            </a-select>
          </div>
        </div>
      </div>
      <a-spin
        class="bottom"
        :spinning="spinning"
        tip="信息获取中..."
        size="large"
      >
        <a-row>
          <a-col :span="24">
            <span>算力增量</span>
            <span>{{ statistics.power_delta }}</span>
          </a-col>
          <a-col :span="24">
            <span>算力增速</span>
            <span>{{ statistics.power_growth }}</span>
          </a-col>
          <a-col :span="24">
            <span>
              存储服务器当量
              <a-tooltip placement="top">
                <template slot="title">
                  以官方Benchmark推荐配置（AMD Ryzen Threadripper 3970X、NVidia
                  GTX 2080Ti、128GB
                  2133mhz）视为1台基准存储服务器，存储服务器当量为该存储服务提供者换算成标准存储服务器的数量（存储服务提供者的算力增速与该基准存储服务器增速的比值）。
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <span>{{ statistics.equivalent_miners }}</span>
          </a-col>
          <a-col :span="24">
            <span>
              出块数量
              <a-tooltip placement="top">
                <template slot="title">
                  Filecoin存储服务模型中，一个高度（tipset）下可能有多个区块（block），每个区块可能获得多份奖励（win
                  count）。累计出块份数=每次出块获得奖励份数的总和
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <span>{{ statistics.blocks_mined }}</span>
          </a-col>
          <a-col :span="24">
            <span>出块奖励</span>
            <span>{{ statistics.total_rewards }}</span>
          </a-col>
          <a-col :span="24">
            <span>出块奖励占比</span>
            <span>{{ statistics.total_rewards_ratio }}</span>
          </a-col>
          <a-col :span="24">
            <span>
              出块份数
              <a-tooltip placement="top">
                <template slot="title">
                  Filecoin存储服务模型中，一个高度（tipset）下可能有多个区块（block），每个区块可能获得多份奖励（win
                  count）。累计出块份数=每次出块获得奖励份数的总和
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <span>{{ statistics.weighted_blocks_mined }}</span>
          </a-col>
          <a-col :span="24">
            <span>存储服务效率</span>
            <span>{{ statistics.mining_efficiency }}</span>
          </a-col>
          <a-col :span="24">
            <span>
              幸运值
              <a-tooltip placement="top">
                <template slot="title">
                  实际爆块数量和理论爆块数量的比值。若存储服务提供者有效算力低于1PiB，则该值存在较大随机性，仅供参考。
                </template>
                <a-icon type="info-circle" />
              </a-tooltip>
            </span>
            <span>{{ statistics.lucky_value }}</span>
          </a-col>
        </a-row>
      </a-spin>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { List, Radio, Select, Tooltip, Pagination } from "ant-design-vue";
Vue.use(List).use(Radio).use(Select).use(Tooltip).use(Pagination);

import Echart from "./echart";

export default {
  components: { Echart },
  // 数据
  data() {
    return {
      overview: {},
      statistics: {},
      duration: "24h",
      spinning: false,
      echartGetDatasFlag: false,
    };
  },
  // 计算属性
  computed: {},
  // 数据监听
  watch: {
    duration(newVal) {
      this.statistics = {};
      this.spinning = true;
      this.$axios
        .get("miner-stats", {
          params: { address: this.$route.params.id, duration: newVal },
        })
        .then((res) => {
          this.spinning = false;
          if (res.code === 0) {
            this.statistics = res.data;
          } else {
            console.log(res.message);
            this.$message.console.error(res.message);
          }
        });
    },
  },
  // 方法
  methods: {
    async getOrePool() {
      this.echartGetDatasFlag = false;
      await this.$axios
        .get("miner", { params: { id: this.$route.params.id } })
        .then((res) => {
          if (res.code === 0) {
            this.statistics = res.data.stats;
            delete res.data.stats;
            this.overview = res.data;

            sessionStorage.setItem(
              "echart-datas",
              JSON.stringify({
                account_balance: res.data.account_balance,
                available_balance: res.data.available_balance,
                sector_pledge_balance: res.data.sector_pledge_balance,
                vesting_funds: res.data.vesting_funds,
                available_balance_val: res.data.available_balance_val,
                sector_pledge_balance_val: res.data.sector_pledge_balance_val,
                vesting_funds_val: res.data.vesting_funds_val,
              })
            );
            this.echartGetDatasFlag = true;
          } else {
            console.log(res.message);
            this.$message.error(res.message);
          }
        });
    },
  },
  created() {
    // （this）data、methods 已初始化，数据（data）、方法(methods) 尚未 编译成模版，只是存在内存中
    // console.log(this.$route.path + " created: \n");
    this.getOrePool();
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less" scoped>
#Ore-Pool-View {
  font-size: 18px;

  > div[id] {
    > .top {
      display: flex;
      justify-content: space-between;
    }

    > .bottom {
      background-color: #3d447b;
      box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
      border-radius: 0.8em;
      overflow: hidden;
      margin-top: 0;

      > div > .ant-row {
        padding: 1em;

        > .ant-col {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5em;

          > *:first-child:not(:last-child) {
            &::after {
              content: ":";
              margin-left: 0.2em;
            }
          }
        }
      }
    }
  }

  > div + div {
    margin-top: 1em;
  }

  #miner-overview {
    > .bottom {
      > div > .ant-row {
        &:not(:last-child) {
          // margin-bottom: 1em;
          padding-bottom: 0;
        }

        &:first-child {
          //: 1.5em !important;
        }

        &:nth-child(2) {
          display: flex;
          justify-content: space-between;
          align-content: flex-end;
          align-items: flex-end;

          &::before,
          &::after {
            display: none;
          }

          > .ant-col:first-child {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: flex-start;
            align-items: flex-start;

            > *:last-child {
              //: 1.3em;
            }
          }

          > .ant-col:not(:first-child) {
            > *:first-child {
              margin-right: 0.5em;
            }
          }
        }

        &:nth-child(4) {
          > .ant-col:last-child {
            align-content: flex-start;
            align-items: flex-start;
            white-space: nowrap;

            > span:last-child > .ant-row > .ant-col {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
